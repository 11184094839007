
import { format } from "date-fns";
import { mapGetters } from "vuex";
import { setAttributeToElements } from "~/utils";
export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getSystemDateFormat: "general/getSystemDateFormat",
    }),
    createdAt() {
      return format(
        new Date(this.article.created_at),
        this.getSystemDateFormat
      );
    },
    image() {
      return this.article?.medias?.find((el) => el.position === "list") || "";
    },
  },
  mounted() {
    setAttributeToElements("a.hidden-ssr-link", "tabindex", "0");
  },
};
