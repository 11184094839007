
import ArticleCard from "~/components/common/ArticleCard";
import UiSwiperCarousel from "~/components/UI/UiSwiperCarousel.vue";
export default {
  name: "ArticlesBlock",
  components: {
    UiSwiperCarousel,
    ArticleCard,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mapedData() {
      return this.blockData.map((el) => ({
        ...el,
        image: el?.images?.[0]?.url,
      }));
    },
  },
};
